$background-danger : #B72828;
$background-secondary: #68B92B;
$color-white: #fff;
$column-text-alignment: center;
$font-sm: 11px;
$border-radius-sm: 4px 4px 4px 4px;


.comparison-result-btn {
    margin-top: 32px;
    // height: 28px;
    // max-width: 100%;
    // width: 140px;
    margin-right: 10px;
    // border-radius: 4px;
    // border: 1px solid var(--border_color);
    // background-color: var(--btn_background_color);
    // font-size: var(--font-text-size);
    // color: white;
    // text-transform: capitalize;

    .btn-primary {
        margin-top: 2px;
    }

    @media screen and (max-width: 767px) {
        max-width: 60%;
        margin-left: 1%;
    }
}

.cluster-Compare {

    .SubHeader {
        .headDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .subheader-filter {
            width: 10% !important;
        }

        .arrowBack {
            cursor: pointer;
        }
    }
}


.grid-status {

    .statusData {
        .status-box-style {
            color: $color-white;
            min-width: 40px;
            min-height: 15px;
            float: left;
            margin-top: 5px;
            text-align: $column-text-alignment;
            font-size: $font-sm;
            border-radius: $border-radius-sm;
        }

        .down {
            background-color: $background-danger;
        }

        .up {
            background-color: $background-secondary;
        }

    }

}