.technical-card {
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    background-color: var(--background-color-default);
    border: none;
    padding: 1.875rem 1.875rem 5.625rem;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width : 768px) {
        width: unset;
        margin-bottom: 10px
    }

    @media screen and (min-width : 768px) and (max-width: 950px) {
        gap: 20px;
        width: 84%;
    }

    @media only screen and (min-width: 1441px) {
        width: calc(20% - 9px);
    }

    @media screen and (max-width : 1440px) and (min-width : 1024px) {
        width: calc(20% - 10px);
    }

    .title {
        font-size: 1.5em;
        color: var(--url-color);
        font-weight: 500;
        margin-bottom: 1.25rem;

        @media screen and (min-width : 2000px) {
            font-size: 2.2rem;
        }
    }

    .sub-title {
        font-size: 1em;
        color: var(--url-color);
        margin-bottom: 1.875rem;
        color: color(--font-color-default);
        letter-spacing: 0.24px;
        font-weight: 500;

        @media screen and (min-width : 2000px) {
            font-size: 2em;
        }
    }

    .content {
        display: flex;
        align-items: center;

        .content-title {
            margin-left: 1rem;
            font-size: 1em;
            letter-spacing: 0.24px;

            @media screen and (min-width : 2000px) {
                font-size: 1.9em;
            }
        }
    }
}