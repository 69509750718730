@import "../../../../../assets/themes/default/share.scss";
.Innerdetails {
    padding: 14px 6px;

    .grid {
        #grid-container {
            .dx-datagrid-headers.dx-datagrid-nowrap {
                background-color: var(--table-body-header);
                font-weight: 700;
                box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);
                border-left: 0px;
                border-right: 0px;
                margin-bottom: 2px;
                .dx-datagrid-drag-action .dx-column-indicators {
                    span {
                        max-height: 14px;
                        color: $primary;
                    }
                }

            }

            .dx-datagrid-rowsview {
                background-color: var(--table-body-row);
                box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);
    
                &.dx-last-row-border {
                    border-radius: 0px 0px 8px 8px;
                }
            }
    
            &.dx-datagrid-borders>.dx-datagrid-rowsview {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
            }
    
            .dx-row-lines>td {
                border-bottom: 2px solid var(--table-header-border-bottom);
            }

            .dx-master-detail-cell {
                padding: 0px;
            }

            #grid-container-nested {
                .dx-datagrid-headers.dx-datagrid-nowrap {
                    background-color: var(--table-body-header);
                    font-weight: 700;
                    box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);
                    border-left: 0px;
                    border-right: 0px;
                    margin-bottom: 2px;

                    .dx-datagrid-drag-action .dx-column-indicators {
                        span {
                            max-height: 14px;
                            color: $primary ;
                        }
                    }

                }
            }
        }
    }
}

.actionBtn {
    .dx-button .dx-button-content {
        padding: 6px !important;
        font-size: 11px !important;
    }

}

.status-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .status-column-circle {
        width: 12px;
        height: 12px;
        // margin: 4px 8px 4px 90px;
        margin-right: 4px;
        border-radius: 8px;
    }

    .yellow {
        background-color: #f7b500;
    }

    .green {
        background-color: #6dd400;
    }

    .status-column-text {
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.45;
        letter-spacing: normal;
        color: #495057;
    }
}