$primaryColor: #26c484;
$backgroundColor: #a6f4d5;
$secondaryColor: #006c40;
$borderRadius: 5px;
$buttonHeight: 50px;
$width: 22.2vw;

.support-card {
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    background-color: var(--background-color-default);
    border: none;
    // padding: 1.875rem 1.875rem 2.187rem;
    padding: 1.25rem;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width : 768px) {
        width: unset;
        margin-bottom: 10px
    }

    @media screen and (min-width : 768px) and (max-width: 950px) {
        gap: 20px;
        width: 90%;
    }

    @media only screen and (min-width: 1024px) {
        width: calc(20% - 7px);
    }

    .title {
        font-size: 1.5em;
        color: var(--url-color);
        font-weight: 500;
        margin-bottom: 1.25rem;

        @media screen and (min-width : 2000px){
            font-size: 2.2em;
        }
    }

    .sub-title {
        font-size: 1em;
        color: var(--url-color);
        margin-bottom: 1.875rem;
        color: var(--font-color-default);
        font-weight: 500;
        letter-spacing: 0.24px;

        @media screen and (min-width : 2000px){
            font-size: 1.8em;
        }
    }

    .ticket-button {
        // width: 100%;
        // height: $buttonHeight;
        background-color: $backgroundColor;
        border-radius: $borderRadius;
        display: flex;
        margin-bottom: 0.94rem;


        .icon-container {
            // width: 15%;
            // height: $buttonHeight;
            background-color: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $borderRadius;
            color: #fff;
            padding: 0.94rem 0.94rem 1rem;

            svg {
                @media screen and (max-width : 1023px) {
                    width: 20px;
                    height: 20px
                }

                @media screen and (min-width : 2000px) {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        p {
            // width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $secondaryColor;
            font-size: 1.125em;
            text-transform: capitalize;
            font-weight: bold;
            letter-spacing: 0.27px;
            text-align: center;
            flex: 1;

            @media screen and (min-width : 2000px) {
                font-size: 1.8em;
            }
        }
    }
}