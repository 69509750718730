.release-card {
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    background-color: var(--background-color-default);
    border: none;
    // padding: 1.875rem 1.875rem 2.781rem;
    padding: 1.25rem;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width : 768px) {
        width: unset;
        margin-bottom: 10px
    }

    @media screen and (min-width : 768px) and (max-width: 950px) {
        gap: 20px;
        width: 90%;
    }

    @media only screen and (min-width: 1441px) {
        width: calc(53% - 11px);
    }

    @media screen and (max-width : 1440px) and (min-width : 1024px) {
        width: calc(53% - 5px);
    }


    .title {
        font-size: 1.5em;
        color: color(--font-color-default);
        font-weight: 500;
        margin-bottom: 1.25rem;


        @media screen and (min-width : 2000px) {
            font-size: 2.2rem;
        }
    }

    .table-header {
        background-color: #f0f4fa;
    }

    h3 {
        font-size: 1.06em;
        // color: #000;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .overflow-custom-table {
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    #custom-table {
        border-collapse: collapse;
        width: 100%;

        td,
        th {
            border-bottom: 1px solid #ddd;
            padding: 0.625rem 0px 0.625rem 1.875rem;
        }

        tr:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        th {
            padding: 0.625rem 0px 0.625rem 1.875rem;
            text-align: left;
            font-size: 1em;
            font-weight: 500;
            letter-spacing: 0.24px;


            @media screen and (min-width : 2000px) {
                font-size: 1.9em;
            }

        }

        .productlisturl {
            font-weight: bold;
            font-size: 15px;
        }


    }

    .custom-table {
        border-collapse: collapse;
        width: 100%;
        background-color: var(--sub-table-background);

        @media screen and (min-width : 2000px) {
            height: 20vh;
        }

        td,
        th {
            border-bottom: solid 1px #d7dade;
        }

        thead,
        th {
            padding: 1.125rem 0px 1.125rem 1.875rem;
            font-size: 1em;
            font-weight: 500;
            color: var(--font-color-default);
            letter-spacing: 0.24px;
            text-align: left;
            margin-bottom: 0.0625rem;

            @media screen and (min-width : 2000px) {
                max-height: 60px;
                font-size: 1.5em;
            }
        }

        tbody,
        td {
            padding: 0.625rem 0px 0.625rem 1.875rem;
            font-size: 1em;
            letter-spacing: 0.21px;
            color: var(--table-color);

            // @media screen and (min-width : 450px) {
            //     font-size: 1em;
            // }

            @media screen and (min-width : 2000px) {
                font-size: 1.5em;
            }

            .version-launch {
                color: #0078d4;
                font-size: 1em;
                font-weight: bold;


                // @media screen and (min-width : 450px) {
                //     font-size: 1em;
                // }               
            }
        }
    }
}